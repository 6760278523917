import React, { createContext, useState } from 'react';

import profileImage from "../assets/images/michail_angelos_kavvadas_profile_400x400.jpg";

import { faEnvelope, faMobile, faLocationDot, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faSkype } from '@fortawesome/free-brands-svg-icons';

const ProfileContext = createContext();

const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState({
    fullname: "Michail Angelos Kavvadas",
    displayname: "Michail Angelos K.",
    image: profileImage,
    title: "IT Project Manager",
    brandingTitle: "Tech Solutions Expert – Consulting",
    description: "Throughout my career spanning multiple industries including advertising, maritime, pharmaceutical, and manufacturing, I have progressed from a full stack web developer to an IT Specialist and ultimately to an IT Project Manager. I am known for my professionalism, positive mindset, and adept team management skills, demonstrating a proven ability to identify, analyze, and solve complex problems. I am passionate about building and motivating dynamic teams to achieve exceptional results.",
    contact_information : [
      {
        name: "email",
        icon: faEnvelope,
        link: "mailto:makavvadas@gmail.com",
        label: "makavvadas@gmail.com",
      },
      {
        name: "telephone",
        icon: faMobile,
        link: "tel:+306932299918",
        label: "+30 6932299918",
      },
      {
        name: "location",
        icon: faLocationDot,
        link: "https://maps.app.goo.gl/UwnTe5n9rKPNjwfA6",
        label: "Sinasou 8, Chaidari 124 61, Athens, Greece",
        region: "Athens, Greece",
      },
      {
        name: "website",
        icon: faGlobe,
        link: "www.kavvadas.gr",
        label: "www.kavvadas.gr",
      },
      {
        name: "linkedin",
        icon: faLinkedinIn,
        link: "https://www.linkedin.com/in/michailangelos/",
        label: "linkedin.com/in/Michail Angelos Kavvadas",
      },
      {
        name: "skype",
        icon: faSkype,
        link: "https://join.skype.com/invite/ljrKDCowQWUD",
        label: "Michail Angelos Kavvadas",
      },

    ],
    education: [
      {
        title: "BACHELORS DEGREE - COMPUTER PROGRAMMING",
        university: "Athens University of Applied Sciences",
        period: "2004 – 2009",
        location: "Athens, Greece",
      }
    ],
    development: [
      {
        title: "PMP (2019)",
        provider: "GreyCampus"
      },
      {
        title: "Python Course (2012)",
        provider: "Coursera by Rice University"
      },
      {
        title: "Computer Graphics and Artificial Intelligence 13th International Conference (2010)",
        provider: "TEI of Athens - Universite de Limoges"
      },
      {
        title: "Computer Graphics and Artificial Intelligence 10th International Conference (2007)",
        provider: "TEI of Athens - Universite de Limoges"
      },
    ],
    languages: [
      "Greek",
      "English",
    ],
    skills: [
      "Problem Solving",
      "Critical thinking",
      "Solution design",
      "Strategic Planning",
      "Adaptability",
      "Proactive",
      "PHP",
      "Java (including Java EE and Java SE)",
      "JavaScript (including jQuery)",
      "React",
      "Node.js",
      "CSS/CSS3",
      "HTML/HTML5",
      "MySQL",
      "MongoDB",
      "WordPress (CMS)",
      "Drupal (CMS)",
      "Joomla (CMS)",
      "Custom CMS",
      "Learndash (LMS)",
      "Moodle (LMS)",
      "Photoshop",
      "Windows (Operating System)",
      "macOS (Operating System)",
      "Linux (Operating System)",
      "Python (Programming Language)",
      "Project Management",
      "Research and Development",
      "ERP and MRP System Implementation",
      "Software Development",
      "LMS Solution Implementation",
    ],
    experience: [
      {
        title: "Project Manager",
        company: "ENKI Inc.",
        duration: "01/2022 – 06/2024",
        location: "Los Angeles, California, United States",
        achievements: [
          "Led research and development on TTS for real-time translation and transcripts, leveraging Azure Cognitive Services.",
          "Designed and developed custom web dashboards for private companies and California public institutions.",
          "Developed a MERN-based web application for recruiting services.",
        ],
      },
      {
        title: "Senior Consultant",
        company: "Apogee Information Systems",
        duration: "04/2022 – 05/2024",
        location: "Thessaloniki, Central Macedonia, Greece",
        achievements: [
          "Implemented LMS solutions customized for private and European sectors.",
          "Established collaborative partnerships with universities in Greece and across Europe.",
        ],
      },
      {
        title: "Senior Consultant",
        company: "SingularLogic",
        duration: "02/2020 – 03/2022",
        location: "Athens, Greece",
        achievements: [
          "Successfully conducted detailed service analysis and implemented solutions for both private and public sector clients, leading to improved efficiency and service delivery",
          "Streamlined operations, enhanced features, and improved functionality in Warehouse, Supply Chain, Inventory, Production, and Sales management.",
          "Integrated and optimized ERP and CRM systems, along with Mobile and Web Services, enhancing overall business processes and increasing customer satisfaction.",
          "Developed and deployed comprehensive dashboards, MIS, and reporting tools, providing actionable insights and facilitating data-driven decision-making",
        ],
      },
      {
        title: "Project Manager - Information Technology",
        company: "OSCAR SA",
        duration: "09/2017 – 02/2020",
        location: "Athens, Greece",
        achievements: [
          "Managed the replacement and update of previous ERP and MRP systems.",
          "Organized and tracked decisions and project tasks in stakeholder meetings.",
          "Mobilized resources to define the scope of software development projects.",
          "Provided project status reporting.",
          "Implemented new information technologies and techniques.",
          "Supported and maintained infrastructure.",
        ],
      },
      {
        title: "Full Stack Web Developer",
        company: "INDAGO",
        duration: "03/2017 – 11/2017",
        location: "Athens, Greece",
        achievements: [
          "Designed and analyzed the architecture for a web platform connecting pharmaceuticals, warehouses, and pharmacies",
          "Developed and tested the platform.",
          "Collaborated with third parties on web projects.",
        ],
      },
      {
        title: "Project Coordinator",
        company: "OSCAR SA",
        duration: "10/2016 – 03/2017",
        location: "Athens, Greece",
        note: "Coordinating software houses MSG (MRP) and Softone (ERP) for Production and Logistic purposes",
        achievements: [
          "Proficient in interpersonal and communication skills",
          "Coordinated software synchronization, testing, and fine-tuning.",
          "Oversaw risk and issue management for ERP and MRP systems.",
          "Generated and managed report creation.",
          "Provided necessary administrative support.",
        ],
      },
      {
        title: "IT SPECIALIST – WEB DEVELOPER",
        company: "PHARMASERVICE",
        duration: "12/2013 – 10/2016",
        location: "Athens, Greece",
        achievements: [
          "Managed project organization for a B2B ordering platform.",
          "Developed websites and managed the implementation of ordering systems.",
          "Provided technical support and training for pharmacists.",
          "Implemented information technologies and techniques.",
          "Supported and maintained infrastructure.",
        ],
      },
      {
        title: "Web Developer Java",
        company: "Selectron",
        duration: "02/2013 – 06/2013",
        location: "Athens, Greece",
        achievements: [
          "Developed software for maritime monitoring.",
          "Collected and analyzed data from PLCs, sensors, and actuators.",
          "Designed and built an e-commerce platform and corporate website.",
        ],
      },
      {
        title: "Junior Java Developer",
        company: "Noetron S.A.",
        duration: "04/2008 – 01/2010",
        location: "Athens, Greece",
        achievements: [
          "Developed full-stack solutions using Oracle Portal.",
          "Designed and developed custom in-house applications.",
          "Collaborated within an agile team to develop and test multiple government portals.",
        ],
      },
    ],
  });

  return (
    <ProfileContext.Provider value={{ profile, setProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};

export { ProfileContext, ProfileProvider };