import React from 'react';
import { useContext } from 'react';
import { ProfileContext } from '../context/ProfileProvider';

function Languages() {
  const { profile } = useContext(ProfileContext);

  const module = {
    title: "LANGUAGES",
    items: profile.languages,
  };

  return (
    <React.Fragment>
      <div className="row pb-4">
        <div className="col-12">
          <h5 className="fw-bold text-success border-bottom border-2 border-success">{module.title}</h5>
        </div>
        <div className="col-12">
          {module.items.map((skill, index)=>{
            return (
              <small key={index} className="d-flex text-dark fw-semibold">{skill}</small>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  );
}
export default Languages;