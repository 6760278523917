import React from 'react';
import { useContext } from 'react';
import { ProfileContext } from '../context/ProfileProvider';

import ContactInfo from "../shared/ContactInfo";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

function getLatestYear(duration) {
  const parts = duration.split(' – ');
  const endDate = parts[1];
  const year = endDate.split('/')[1];
  return parseInt(year, 10);
}

function getEarliestYear(duration) {
  const parts = duration.split(' – ');
  const startDate = parts[0];
  const year = startDate.split('/')[1];
  return parseInt(year, 10);
}


function Landing() {
  const { profile } = useContext(ProfileContext);

  const profileEmail = profile.contact_information.find(item => item.name === "email");
  const profileLocation = profile.contact_information.find(item => item.name === "location");
  const profilePhone = profile.contact_information.find(item => item.name === "telephone");
  const profileLinkedin = profile.contact_information.find(item => item.name === "linkedin");

  const contactInfo = [
    {
      title: "email me",
      icon: profileEmail.icon,
      label: profileEmail.label,
      link: profileEmail.link,
    },
    {
      title: "my base",
      icon: profileLocation.icon,
      label: profileLocation.region,
      link: profileLocation.link,
    },
    {
      title: "call me",
      icon: profilePhone.icon,
      label: profilePhone.label,
      link: profilePhone.link,
    }
  ];

  return (
    <React.Fragment>

      <div className="container-fluid vh-100 d-flex flex-column">
        <div className="row flex-grow-1">
          <div className="col-12 col-lg-8 d-flex-column d-lg-flex justify-content-start align-items-center p-0 py-4 bg-primary">
            {contactInfo.map((item,index)=>{
              return(
                <Link key={index} to={item.link} className="d-flex justify-content-center align-items-center text-white px-4 border-none border-end border-dashed border-0 border-lg-2 border-white text-decoration-none">
                  <FontAwesomeIcon icon={item.icon} className="fs-5 pe-3"/>
                  <blockquote class="d-flex d-lg-block blockquote fs-6 m-0 py-2 py-lg-4">
                    <p className="fw-bold m-0 pe-3 p-lg-0">{item.title}</p>
                    <footer>{item.label}</footer>
                  </blockquote>
                </Link>
              );
            })}
          </div>
          <div className="d-none d-lg-flex col-lg-4 justify-content-end align-items-center bg-primary p-4">
            <Link to={profileLinkedin.link} target="_blank" underline="none" className="d-flex justify-content-start align-items-center pb-3">
              <FontAwesomeIcon icon={profileLinkedin.icon} className="text-white fs-5 pe-2"/>
            </Link>
          </div>
        </div>

        <div className="row flex-grow-3 py-4">
          <div className="col-12 p-4 pt-0 text-center text-lg-start">
            <h2 className="fw-bold text-success m-0">{profile.displayname}</h2>
            <span className="fw-bold text-primary">{profile.brandingTitle}</span>
          </div>
          <div className="col-12">
            <h1 className="text-center fw-bold text-primary pb-4 my-4">Industries</h1>
            <Timeline position="alternate">
              {profile.experience.map((work, index)=>{
                return(
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot className=" bg-success border border-light border-4"/>
                      <TimelineConnector className="bg-success"/>
                    </TimelineSeparator>
                    <TimelineContent>
                      <h5 className="text-primary m-0">{work.company}</h5>
                      <small className="font-bold text-primary">{work.title} {getEarliestYear(work.duration)}</small>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          </div>
        </div>

        <div className="row flex-grow-1">
          <div className="col-12 col-lg-8 d-flex justify-content-center justify-content-lg-start align-items-center p-0 py-4 bg-primary">
            <small className="text-white px-4">© Michail Angelos K. ☀</small>
          </div>
          <div className="col-12 col-lg-4 d-flex justify-content-center justify-content-lg-end align-items-center bg-primary p-lg-4">
            <Link to={profileLinkedin.link} target="_blank" underline="none" className="d-flex justify-content-start align-items-center pb-3">
              <Link to="/cv" className="fw-bold text-light text-decoration-none fs-6 me-4 pt-1 p-0 m-0">View CV</Link>
              <FontAwesomeIcon icon={profileLinkedin.icon} className="text-white fs-5 pe-2"/>
            </Link>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}
export default Landing;