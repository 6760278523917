import React from 'react';
import { useContext } from 'react';
import { ProfileContext } from '../context/ProfileProvider';

function Skills() {
  const { profile } = useContext(ProfileContext);

  const module = {
    title: "SKILLS",
    items: profile.skills
  };

  return (
    <React.Fragment>
      <div className="row pb-4">
        <div className="col-12">
          <h5 className="fw-bold text-success border-bottom border-2 border-success">{module.title}</h5>
        </div>
        <div className="col-12">
          {module.items.map((skill, index)=>{
            return (
              <span key={index} className="btn btn-sm bg-success text-white me-2 mb-2 disabled opacity-100">{skill}</span>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  );
}
export default Skills;