import React from 'react';
import { useContext } from 'react';
import { ProfileContext } from '../context/ProfileProvider';

function ConferencesAndCourses() {
  const { profile } = useContext(ProfileContext);

  const module = {
    title: "CONFERENCES & COURSES",
    items: profile.development,
  };

  return (
    <React.Fragment>
      <div className="row pb-4">
        <div className="col-12 p-lg-0">
          <h5 className="fw-bold text-success border-bottom border-2 border-success ps-lg-4">{module.title}</h5>
        </div>
        {module.items.map((item, index)=>{
          return (
            <div key={index} className="col-12 px-4 pb-2">
              <h6 className="m-0">{item.title}</h6>
              <small className="text-secondary">{item.provider}</small>
            </div>
          )
        })}
      </div>
    </React.Fragment>
  );
}
export default ConferencesAndCourses;