import React from 'react';
import { useContext } from 'react';
import { ProfileContext } from '../context/ProfileProvider';

function Education() {
  const { profile } = useContext(ProfileContext);

  const module = {
    title: "EDUCATION",
    items: profile.education
  };

  return (
    <React.Fragment>
      <div className="row pb-4">
        <div className="col-12 p-lg-0">
          <h5 className="fw-bold text-success border-bottom border-2 border-success ps-lg-4">{module.title}</h5>
        </div>
        {module.items.map((item, index)=>{
          return (
            <div key={index} className="col-12 px-4 pb-2">
              <h6 className="fw-bolder text-success m-0">{item.title}</h6>
              <span className="fw-semibold text-dark">{item.university}</span>
              <div className="d-flex justify-content-between align-items-center">
                <small className="text-success">{item.period}</small>
                <small className="text-success">{item.location}</small>
              </div>
            </div>
          )
        })}
      </div>
    </React.Fragment>
  );
}
export default Education;