import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import "./assets/css/style.css"

import Landing from "./pages/Landing";
import CV from "./pages/CV";

import { ProfileProvider } from './context/ProfileProvider';

function App() {

  const routes = [
    {
      path: '/',
      element: <Landing />,
    },
    {
      path: '/cv',
      element: <CV />,
    },
  ];

  return (
    <Router>
      <ProfileProvider>
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </ProfileProvider>
    </Router>
  );
}

export default App;
