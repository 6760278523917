import React from 'react';
import { useContext } from 'react';
import { ProfileContext } from '../context/ProfileProvider';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

function WorkExperience() {
  const { profile } = useContext(ProfileContext);

  const module = {
    title: "WORK EXPERIENCE",
    items: profile.experience,
  };

  return (
    <React.Fragment>
      <div className="row pb-4">
        <div className="col-12 p-lg-0">
          <h5 className="fw-bold text-success border-bottom border-2 border-success ps-lg-4">{module.title}</h5>
        </div>
        {module.items.map((item, index)=>{
          return (
            <div key={index} className="col-12 px-4 p-4">
              <h6 className="fw-bolder text-success m-0">{item.title}</h6>
              <span className="fw-semibold text-dark">{item.company}</span>
              <div className="d-flex justify-content-between align-items-center">
                <small className="text-success">{item.duration}</small>
                <small className="text-success">{item.location}</small>
              </div>
              <div className="p-0">
                {item?.note && <small className="d-flex text-secondary">{item.note}</small>}
                <small className="text-success">Achievements/Tasks</small>
                {item.achievements.map((archivement, index)=>{
                  return (
                    <span key={index} className="d-flex justify-content-start aling-items-center">
                      <FontAwesomeIcon icon={faCheck} className="text-success pe-2 pt-1"/>
                      <small>{archivement}</small>
                    </span>
                  );
                })}
              </div>
            </div>
          )
        })}
      </div>
    </React.Fragment>
  );
}
export default WorkExperience;