import React from 'react';
import { useContext } from 'react';
import { ProfileContext } from '../context/ProfileProvider';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from '@mui/material/Link';

function ContactInfo() {
  const { profile } = useContext(ProfileContext);

  return (
    <React.Fragment>
      <div className="row pb-4">
        <div className="col-12">
          {profile.contact_information.map((source, index)=>{
            return (
              <Link key={index} href={source.link} target="_blank" underline="none" className="d-flex justify-content-start align-items-center pb-3">
                <FontAwesomeIcon icon={source.icon} className="pe-2 text-success"/> <small className="text-dark fw-semibold">{source.label}</small>
              </Link>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  );
}
export default ContactInfo;