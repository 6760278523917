import React from 'react';
import { useContext, useRef } from 'react';

import generatePDF, { Resolution, Margin } from 'react-to-pdf';

import Avatar from '@mui/material/Avatar';
import { ProfileContext } from '../context/ProfileProvider';

import Education from "../shared/Education";
import ConferencesAndCourses from "../shared/ConferencesAndCourses";
import WorkExperience from "../shared/WorkExperience";
import ContactInfo from "../shared/ContactInfo";
import Skills from "../shared/Skills";
import Languages from "../shared/Languages";

function CV() {
  const { profile } = useContext(ProfileContext);
  const targetRef = useRef();

  const pdfOptions = {
    filename: `${profile.fullname.replace(' ','_')}_CV_${new Date().toLocaleDateString('en-GB').replace(/\//g, '')}.pdf`,
    page: {
      margin: Margin.SMALL,
    },
    canvas: {
      mimeType: 'image/jpeg',
      qualityRatio: 1
    },
    overrides: {
      pdf: {
         compress: true
      },
    },
  };

  return (
    <React.Fragment>
      <div className="container p-4" ref={targetRef}>

        <div className="row pb-4">
          <div className="col-12 col-lg-3 d-flex justify-content-center align-items-center pb-4 pb-lg-0 pt-4 pt-lg-0 bg-success">
            <Avatar className="" src={profile.image} alt={profile.fullname} sx={{ width: 180, height: 180 }}/>
          </div>
          <div className="col-12 col-lg-9 bg-success text-white p-4">
            <h3>{profile.fullname}</h3>
            <h5>{profile.title}</h5>
            <p className="pt-4">{profile.description}</p>
          </div>
        </div>

        <div className="row pb-4">
          <div className="col-12 col-lg-3">
            <ContactInfo/>
            <Skills/>
            <Languages/>
          </div>
          <div className="col-12 col-lg-9">
            <Education/>
            <ConferencesAndCourses/>
            <WorkExperience/>
          </div>
        </div>

      </div>

      <div className="container p-4">
        <div className="row pb-4">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <button className="btn btn-primary" onClick={() => generatePDF(targetRef, pdfOptions)}>Download PDF</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default CV;